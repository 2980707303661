<template>
  <b-card title="This is the Title">
    <b-card-text>SubHeader</b-card-text>
    <b-card-text>Description</b-card-text>
    <b-table responsive="sm"  :fields="fields"/>
  </b-card>
  
</template>

<script>
import { BCard, BCardText, BTable  } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BTable,
  },
  data() {
    return {
      fields: [
          {
            key: 'id',
            label: 'ID',
            sortable: true
          },
          {
            key: 'project',
            label: 'PROJECT',
            sortable: false
          },
          {
            key: 'client',
            label: 'CLIENT',
            sortable: true,
          },
          {
            key: 'rentable_sqft',
            label: 'RENTABLE SQFT',
            sortable: true,
          },
          {
            key: 'status',
            label: 'STATUS',
            sortable: true,
          }
        ],
      items: [
        {
          age: 40, first_name: 'Dickerson', last_name: 'Macdonald', Occupation: 'Job',
        },
        {
          age: 21, first_name: 'Larsen', last_name: 'Shaw', Occupation: 'Job',
        },
        {
          age: 89, first_name: 'Geneva', last_name: 'Wilson', Occupation: 'Bussiness',
        },
        {
          age: 38, first_name: 'Jami', last_name: 'Carney', Occupation: 'Bussiness',
        },
        {
          age: 40, first_name: 'James', last_name: 'Thomson', Occupation: 'Job',
        },
      ],
    }
  }
}
</script>

<style>

</style>
